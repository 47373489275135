<template style="background-color: white">
  <div id="app-question-add" class="app-add">
    <loading :loading="loading" :text="loadingTip"></loading>
    <el-form ref="form"  label-width="50px">
      <el-form-item :label="rule.questionNo+'、'" v-for="(rule,i) in rules">
        <el-select v-model="rule.subjectId" placeholder="科目" @change="getChapters(rule)">
          <el-option
              v-for="subject in subjects"
              :key="subject.id"
              :label="subject.name"
              :value="subject.id">
          </el-option>
        </el-select>
        <el-select v-model="rule.chapterId" placeholder="章节" @change="getKnowledges(rule)" style="margin-left: 10px; width:200px">
          <el-option
              v-for="chapter in rule.chapters"
              :key="chapter.id"
              :label="chapter.name"
              :value="chapter.id">
          </el-option>
        </el-select>
        <el-select v-model="rule.knowledgeId" placeholder="知识点" style="margin-left: 10px; width:200px">
          <el-option
              v-for="knowledge in rule.knowledges"
              :key="knowledge.id"
              :label="knowledge.name"
              :value="knowledge.id">
          </el-option>
        </el-select>
        <el-select v-model="rule.questionTypeId" placeholder="题型" style="margin-left: 10px; width:100px">
          <el-option
              v-for="questionType in questionTypes"
              :key="questionType.id"
              :label="questionType.name"
              :value="questionType.id">
          </el-option>
        </el-select>
        <el-select v-model="rule.difficultId"  placeholder="难易度" style="margin-left: 10px;width:100px;">
          <el-option
              v-for="difficult in difficults"
              :key="difficult.id"
              :label="difficult.name"
              :value="difficult.id">
          </el-option>
        </el-select>
        <el-select v-model="rule.star"  placeholder="星级" style="margin-left: 10px;width:100px">
          <el-option label="一星" :value="1"></el-option>
          <el-option label="二星" :value="2"></el-option>
          <el-option label="三星" :value="3"></el-option>
        </el-select>
      </el-form-item>
       <div class="el-form-item" style="height: 400px" v-show="loading == false">
        <label class="el-form-item__label"  @click="addOption()" style="width: 100px;cursor: pointer; color: rgb(64, 175, 254);">增加题目</label>
        <div class="el-form-item__content" style="margin-left: 100px;"><label class="el-form-item__label"  @click="cutOption()"  style="width: 100px;cursor: pointer; color: rgb(64, 175, 254);">减少题目</label></div>
      </div>
      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getLocalProjectId,
  UrlEnum,
  QuestionTypeEnum,
  ProjectEnum,
  request,
  TipEnum
} from "../../../../public/js/common-vue";
import SelectDifficult from "@/components/SelectDifficult";
import Loading from "@/components/Loading";

export default {
  name: "Edit",
  data(){
    return{
      loading:false,
      loadingTip:"加载中",
      rules:[],
      subjects:[],
      knowledges:[],
      questionTypes:[],
      difficults:[]
    }
  },
  methods:{
    validate(){
      for(let i=0;i<this.rules.length;i++){
        if(this.rules[i].subjectId === ''){
          this.showMsgError("请选择科目");
          return false;
        }
      }
      return true;
    },
    getSubjects(){
      this.loading = true;
      this.$http({
        method: "get",
        url: UrlEnum.PAPER_RULE_SUBJECTS+"?paperTypeId="+request("id"),
      }).then((res) => {
        this.loading=false;
        this.subjects = res.data.list;
        this.initData();
      }).catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getChapters(rule){
      this.$http({
        method: "get",
        url: UrlEnum.CHAPTER+"?page=1&size=1000&subjectId="+rule.subjectId,
      }).then((res) => {
        rule.chapters = res.data.list;
        if(rule.chapterId != ''){
          if(rule.chapters.length > 0){
            rule.chapterId = rule.chapters[0].id;
            this.getKnowledges(rule);
          }
        }
        console.log(rule.chapters);
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getKnowledges(rule){
      this.$http({
        method: "get",
        url: UrlEnum.KNOWLEDGE+"?page=1&size=1000&chapterId="+rule.chapterId,
      }).then((res) => {
        rule.knowledges = res.data.list;
        if(rule.knowledgeId != ''){
          if(rule.knowledges.length>0){
            rule.knowledgeId = rule.knowledges[0].id;
          }
        }
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getDifficults(){
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION_DIFFICULT,
      }).then((res) => {
        this.difficults = res.data;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData:function (){
      this.loading=true;
      this.$http({
        method: "get",
        url: UrlEnum.PAPER_RULES + '?paperTypeId='+request('id'),
      }).then((res) => {
        this.loading = false;
        this.rules = res.data.list;
        for(let i=0;i<this.rules.length;i++){
          if(this.rules[i].difficultId == 0){
            this.rules[i].difficultId = '';
          }
          if(this.rules[i].star == 0){
            this.rules[i].star = '';
          }
          if(this.rules[i].chapterId == 0){
            this.rules[i].chapterId = '';
          }
          if(this.rules[i].knowledgeId == 0){
            this.rules[i].knowledgeId = '';
          }
          if(this.rules[i].questionTypeId == 0){
            this.rules[i].questionTypeId = '';
          }
        }
        this.getQuestionTypes();
        this.getDifficults();
      }).catch((res) => {
        this.loading = false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    addOption:function (){
      const i = this.rules.length;
      this.rules.push({paperTypeId:request("id"),questionNo:i+1,subjectId:'',chapterId:'',knowledgeId:'',chapters:[],knowledges:[],questionTypeId:''});
    },
    cutOption:function (){
      if(this.rules.length == 0){
        return;
      }
      this.rules.splice(this.rules.length-1,1);
    },
    getQuestionTypes:function(){
      this.$http({
        method: "get",
        url: UrlEnum.PAPER_RULE_QUESTIONTYPES+"?paperTypeId="+request("id"),
      }).then((res) => {
        this.questionTypes = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    submit(){
      if(this.validate() == false){
        return;
      }
      this.loadingTip = "提交中";
      this.loading = true;
      this.$http({
        method: "put",
        url: UrlEnum.PAPER_RULE_TYPES+"/"+request("id"),
        data: this.rules,
      })
      .then((res) => {
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        setTimeout(function () {
          parent.layui.table.reload('tableData'); //重载表格
          parent.layer.close(index); //再执行关闭
        }, 800);
      })
      .catch((res) => {
        this.loading = false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    }
  },
  mounted() {
    this.getSubjects();
},
  components:{Loading, SelectDifficult}
}
</script>


<style scoped>
@import "../../../../static/css/add.css";
</style>
